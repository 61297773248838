import React, { useContext, useEffect } from 'react';
import * as S from './VolumeDiscount.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import useLocalStorage from '../../hooks/useLocalStorage';

const TubSelector = props => {
  const {
    tubs,
    defaultOption,
    boxDesign,
    boxDesignMobile,
    showChildren,
    children,
    checkLocalStorage,
    showInnerChild
  } = props;

  const context = useContext(FunnelContext);

  const [currentTubIndex2, setCurrentTubIndex2] = useLocalStorage(
    'currentTubIndex',
    null
  );

  const {
    currency,
    currentCategory,
    setCurrentProduct,
    tubIndex,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    pageDesign,
    setCurrentTub,
    extraObjects: extraObjectsContext,
    setExtraObjects
  } = context;

  const currencySymbol = currency?.symbol || `$`;

  useEffect(() => {
    if (typeof defaultOption === 'number') {
      setCurrentTubIndex(defaultOption);
      setCurrentProduct(
        currentCategory === `subscriptions`
          ? `subscription_${defaultOption}`
          : `onetime_${defaultOption}`
      );
    }
  }, [currentCategory, setCurrentProduct, defaultOption, setCurrentTubIndex]);

  useEffect(() => {
    const { extraObjects } = tubs[currentTubIndex];

    if (extraObjects) {
      extraObjects.map(({ key, value }) => {
        if (!key) return false;
        const newExtraObjects = Object.assign(extraObjectsContext, {
          [key]: value
        });
        return setExtraObjects(newExtraObjects);
      });
    }

    if (checkLocalStorage) {
      const index = currentTubIndex2 || 0;
      setCurrentTubIndex(index);
      setCurrentProduct(
        currentCategory === `subscriptions`
          ? `subscription_${index}`
          : `onetime_${index}`
      );
    }
  }, [
    tubs,
    currentTubIndex,
    setExtraObjects,
    extraObjectsContext,
    checkLocalStorage,
    currentTubIndex2,
    setCurrentTubIndex,
    setCurrentProduct,
    currentCategory
  ]);

  const TubImage = ({ image, image2, active, alt }) => {
    if (active) {
      switch (pageDesign) {
        case 'design2':
          return <img src={image} alt={alt} width={58} height={58} />;
        case 'design1':
        default:
          return <img src={image2} alt={alt} />;
      }
    } else {
      return <img src={image} alt={alt} width={58} height={58} />;
    }
  };

  const Price = ({ showPrice, index }) => {
    if (!showPrice) return null;
    return (
      <S.Price>
        {currencySymbol}
        <ProductPrice
          type="discounted_price"
          currentCategory={currentCategory}
          currentProduct={
            currentCategory === `onetime`
              ? `onetime_${index}`
              : `subscription_${index}`
          }
        />
      </S.Price>
    );
  };

  return (
    <S.Container className={pageDesign}>
      <S.TubList boxDesignMobile={boxDesignMobile}>
        {tubs?.map((tub, index) => {
          const {
            title,
            subTitle,
            image,
            image2,
            imageHeight,
            showPrice = false,
            hideLabel = false,
            hideSubLabel = true,
            backgroundColor,
            extraObjects,
            showTag = false,
            tagText = '',
            showTopTag = false,
            topTagText = '',
            topTagTextMobile = ''
          } = tub;
          const activeTub = currentTubIndex === index ? 'current' : '';
          const hoverTub = tubIndex === index ? 'active' : '';
          const activeImage = tubIndex === index || currentTubIndex === index;

          const TubLabel = () => {
            if (hideLabel) return null;
            return <span>{title}</span>;
          };

          const TubSubLabel = () => {
            if (hideSubLabel) return null;
            return <span>{subTitle}</span>;
          };

          const BoxDesignInner = () => {
            return (
              <>
                <TubLabel />
                <TubSubLabel />
                <Price showPrice={showPrice} index={index}>
                  {currencySymbol}XX.xx
                </Price>
              </>
            );
          };

          const BoxDesign = () => {
            switch (boxDesign) {
              case 'wide':
                return (
                  <div>
                    <BoxDesignInner />
                  </div>
                );
              case 'normal':
              default:
                return <BoxDesignInner />;
            }
          };

          const handleClick = () => {
            setTubIndex(index);
            setCurrentTubIndex(index);
            setCurrentTub(tub);
            setCurrentProduct(
              currentCategory === `subscriptions`
                ? `subscription_${index}`
                : `onetime_${index}`
            );

            // set extraObjects
            if (extraObjects) {
              extraObjects.map(({ key, value }) => {
                if (!key) return false;
                const newExtraObjects = Object.assign(extraObjectsContext, {
                  [key]: value
                });
                return setExtraObjects(newExtraObjects);
              });
            }

            if (checkLocalStorage) {
              setCurrentTubIndex2(index);
            }
          };

          return (
            <S.TubListItem
              showChildren={showChildren}
              childCount={children?.length}
              key={index}
              className={`tub-list-item ${hoverTub} ${activeTub}`}
              onClick={handleClick}
              pageDesign={pageDesign}
              boxDesign={boxDesign}
              boxDesignMobile={boxDesignMobile}
              tubsLength={tubs.length}
              showTopTag={showTopTag}
            >
              {showChildren ? (
                <>{children[index]}</>
              ) : (
                <S.TubListItemContainer
                  imageHeight={imageHeight}
                  boxDesign={boxDesign}
                  boxDesignMobile={boxDesignMobile}
                  pageDesign={pageDesign}
                  backgroundColor={backgroundColor}
                  className={showTag ? `show-tag` : ``}
                >
                  <div>
                    {showTopTag ? (
                      <S.TopTagContainer className={activeTub}>
                        <S.TopTagTextDesk>{topTagText}</S.TopTagTextDesk>
                        <S.TopTagTextMobile>
                          {topTagTextMobile}
                        </S.TopTagTextMobile>
                      </S.TopTagContainer>
                    ) : (
                      <></>
                    )}
                    <S.ItemContainer
                      className={activeTub}
                      showTag={showTag}
                      showTopTag={showTopTag}
                    >
                      <TubImage
                        image={`${image}?width=100`}
                        image2={`${image2}?width=100`}
                        imageHeight={imageHeight}
                        active={activeImage}
                        alt={title}
                      />
                      <BoxDesign />
                      {showInnerChild && <>{children[index]}</>}
                    </S.ItemContainer>
                    {showTag ? (
                      <S.TagContainer className={activeTub}>
                        <svg
                          width="10"
                          height="9"
                          viewBox="0 0 10 9"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.92051 4.11961L5.24134 0.440443C5.08717 0.286276 4.87467 0.198776 4.65384 0.198776L1.66634 0.198776C1.20801 0.198776 0.833008 0.573776 0.833008 1.03211L0.833008 4.01961C0.833008 4.24044 0.920508 4.45294 1.07884 4.60711L4.75801 8.28628C5.08301 8.61128 5.61217 8.61128 5.93718 8.28628L8.92468 5.29878C9.24968 4.97378 9.24968 4.44878 8.92051 4.11961ZM2.70801 2.69878C2.36217 2.69878 2.08301 2.41961 2.08301 2.07378C2.08301 1.72794 2.36217 1.44878 2.70801 1.44878C3.05384 1.44878 3.33301 1.72794 3.33301 2.07378C3.33301 2.41961 3.05384 2.69878 2.70801 2.69878Z"
                            fill="#F8F8F8"
                          />
                        </svg>{' '}
                        {tagText}
                      </S.TagContainer>
                    ) : (
                      <S.TagContainer className={`${activeTub} empty`}>
                        &nbsp;
                      </S.TagContainer>
                    )}
                  </div>
                </S.TubListItemContainer>
              )}
            </S.TubListItem>
          );
        })}
      </S.TubList>
    </S.Container>
  );
};

export default TubSelector;
